/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'

export const auth = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    keuUser: null,
    role: [],
    currentRole: null,
    isHideSaldo: false
    // user: {
    //   id_user: 1,
    //   name: 'Jhonarendra',
    // },
    // role: [
    //   {
    //     id_role: 1,
    //     nama_role: 'Admin',
    //     abilities: 'admin',
    //   },
    //   {
    //     id_role: 2,
    //     nama_role: 'User',
    //     abilities: 'user',
    //   },
    // ],
    // currentRole: {
    //   id_role: 2,
    //   nama_role: 'User',
    //   abilities: 'user',
    // },
  },
  reducers: {
    setUser: (state, val) => {
      const {
        user, keu_user, role, current_role
      } = val.payload
      state.user = user
      state.keuUser = keu_user
      state.role = role
      state.currentRole = current_role
    },
    setIsHideSaldo: (state, val) => {
      state.isHideSaldo = val.payload
    }
  }
})

export const { setUser, setIsHideSaldo } = auth.actions

export default auth.reducer

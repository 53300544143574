import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

function OnlyPublicMiddleware({ children }) {
  const { user } = useSelector((state) => state.auth)
  const location = useLocation()

  if (user) {
    return <Navigate to="/user" state={{ from: location }} replace />
  }

  return children
}

OnlyPublicMiddleware.propTypes = {
  children: PropTypes.element.isRequired
}

export default OnlyPublicMiddleware

import React from 'react'

export default function Error403() {
  return (
    <div className="min-h-screen text-center dark:bg-gray-900 dark:text-gray-200 pt-48">
      <h3 className="text-6xl font-bold">403</h3>
      <p className="mt-4 text-xl uppercase">Forbidden</p>
    </div>
  )
}

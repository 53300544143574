/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'

export const toast = createSlice({
  name: 'toast',
  initialState: {
    toastData: {}
  },
  reducers: {
    setToastData: (state, val) => {
      state.toastData = {
        ...val.payload,
        toastId: +new Date()
      }
    }
  }
})

export const {
  setToastData
} = toast.actions

export default toast.reducer

import { createSlice } from '@reduxjs/toolkit'

export const main = createSlice({
  name: 'main',
  initialState: {
    version: '24.6.17.19', // preset pilih nominal di transfer
    loadingOverlay: false
  },
  reducers: {
    setVersion: (state, val) => {
      state.version = val
    },
    setLoadingOverlay: (state, val) => {
      state.loadingOverlay = val.payload
    }
  }
})

export const { setLoadingOverlay } = main.actions

export default main.reducer

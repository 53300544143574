import axios from 'axios'

const authApi = {
  getCookie: () => axios.get(`${axios.defaults.baseURL}/sanctum/csrf-cookie`),
  login: (form) => axios.post(`${axios.defaults.baseURL}/login`, {
    appName: 'keuangan',
    username: form.email,
    password: form.password
  }),
  getUser: (params) => axios.get(`${axios.defaults.baseURL}/api/user`, { params })
}

export default authApi

import db from './db'

const keuKatController = {
  index: () => db.keuKat.toArray(),
  storeMulti: (data) => db.keuKat.bulkAdd(data),
  delete: (params) => {
    let dexie = db.keuKat
    if ('where' in params && 'above' in params) {
      dexie = dexie.where(params.where).above(params.above)
    }
    return dexie.delete()
  }
}

export default keuKatController

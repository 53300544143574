import db from './db'

const userLoginController = {
  index: () => db.userLogin.toArray(),
  store: (data) => db.userLogin.add({
    user: data.user,
    currentRole: data.current_role,
    keuUser: data.keu_user,
    role: data.role
  }),
  delete: (params) => {
    let dexie = db.userLogin
    if ('where' in params && 'above' in params) {
      dexie = dexie.where(params.where).above(params.above)
    }
    return dexie.delete()
  }
}

export default userLoginController

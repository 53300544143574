import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function ToastComp() {
  const toastData = useSelector((state) => state.toast.toastData)

  useEffect(() => {
    if ('toastId' in toastData) {
      toast(toastData.msg, {
        type: toastData.type,
        theme: 'colored',
        hideProgressBar: true
      })
    }
  }, [toastData])

  return (
    <div>
      <ToastContainer />
    </div>
  )
}

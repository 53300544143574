import { createSlice } from '@reduxjs/toolkit'

export const dompet = createSlice({
  name: 'dompet',
  initialState: {
    dompetList: [],
    dompet: [
      {
        id_keu_dompet: 1,
        icon: 'money-bag.png',
        color: '#FFF3E0',
        desc: 'Uang kas di dompet',
        name: 'Kas',
        saldo: 150000
      },
      {
        id_keu_dompet: 2,
        icon: 'envelope.png',
        color: '#ECEFF1',
        desc: 'Uang di rumah',
        name: 'Amplop',
        saldo: 500000
      },
      {
        id_keu_dompet: 3,
        icon: 'bank.png',
        color: '#ECEFF1',
        desc: 'Tabungan di bank',
        name: 'Tabungan',
        saldo: 296770123
      },
      {
        id_keu_dompet: 4,
        icon: 'credit-card.png',
        color: '#FFF8E1',
        desc: 'ATM',
        name: 'ATM',
        saldo: 4980022
      },
      {
        id_keu_dompet: 5,
        icon: 'credit-card.png',
        color: '#FBE9E7',
        desc: 'BNI',
        name: 'Bank BNI',
        saldo: 4980022
      },
      {
        id_keu_dompet: 6,
        icon: 'credit-card.png',
        color: '#E8EAF6',
        desc: 'BCA',
        name: 'Bank BCA',
        saldo: 4980022
      },
      {
        id_keu_dompet: 7,
        icon: 'credit-card.png',
        color: '#E8EAF6',
        desc: 'BRI',
        name: 'Bank BRI',
        saldo: 4980022
      },
      {
        id_keu_dompet: 8,
        icon: 'credit-card.png',
        color: '#E3F2FD',
        desc: 'Gopay',
        name: 'Gopay',
        saldo: 4980022
      },
      {
        id_keu_dompet: 9,
        icon: 'credit-card.png',
        color: '#FFF3E0',
        desc: 'Shopeepay',
        name: 'Shopeepay',
        saldo: 4980022
      }
    ]
  },
  reducers: {
    setDompetList: (state, val) => {
      state.dompetList = val.payload
    },
    setDompet: (state, val) => {
      state.dompet = val
    }
  }
})

export const {
  setDompetList
} = dompet.actions

export default dompet.reducer

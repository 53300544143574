import dexieApi from '../services/dexie'

const getListBulanAndTahun = async () => {
  const listBulanNew = []
  const listTahunNew = []
  const first = await dexieApi.keu.index({ orderBy: 'date', first: true })
  let firstMonth = null
  let firstYear = null
  if (first) {
    firstMonth = new Date(first.date).getMonth() + 1
    firstYear = new Date(first.date).getFullYear()
  } else {
    firstMonth = new Date().getMonth() + 1
    firstYear = new Date().getFullYear()
  }
  const last = await dexieApi.keu.index({ orderBy: 'date', reverse: true, first: true })
  let lastMonth = null
  let lastYear = null
  if (last) {
    lastMonth = new Date(last.date).getMonth() + 1
    lastYear = new Date(last.date).getFullYear()
  } else {
    lastMonth = new Date().getMonth() + 1
    lastYear = new Date().getFullYear()
  }

  // list tahun da bulan
  for (let i = firstYear; i <= lastYear; i += 1) {
    listTahunNew.push(i)
    let fromMonth = 1
    let toMonth = 12
    if (i === firstYear) {
      fromMonth = firstMonth
    }
    if (i === lastYear) {
      toMonth = lastMonth
    }
    for (let j = fromMonth; j <= toMonth; j += 1) {
      listBulanNew.push(`${j}-${i}`)
    }
  }
  return { listBulanNew, listTahunNew }
}

export default getListBulanAndTahun

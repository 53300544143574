import React from 'react'
import { Link } from 'react-router-dom'

import Counter from '../../components/develop/counter'

export default function Develop() {
  return (
    <div>
      <div>
        <Link to="/">Home</Link>
        <Link to="/user">User</Link>
      </div>
      <Counter />
      {/* untuk ngetrigger tailwind */}
      <div>
        <div className="
            grid
            grid-cols-3
            gap-4
          "
        />
        <span className="
        py-1
        px-2
        text-xs
        bg-brand-100
        dark:bg-brand-900
        text-brand-600
        dark:text-brand-300
        whitespace-nowrap
        rounded-full
        font-medium
       border border-2 border-brand-600 dark:border-brand-300"
        >
          <span>Juli 2022</span>
        </span>
        <div className="border-transparent " />
        <div className="dark:border-gray-200 border-gray-900" />
        <div className="bg-brand-400" />
        <div className="grid grid-cols-8 gap-4">
          <div className="p-8 bg-gray-50" />
          <div className="p-8 bg-stone-50" />
          <div className="p-8 bg-red-50" />
          <div className="p-8 bg-red-50" />
          <div className="p-8 bg-orange-50" />
          <div className="p-8 bg-amber-50" />
          <div className="p-8 bg-yellow-50" />
          <div className="p-8 bg-lime-50" />
          <div className="p-8 bg-green-50" />
          <div className="p-8 bg-emerald-50" />
          <div className="p-8 bg-teal-50" />
          <div className="p-8 bg-cyan-50" />
          <div className="p-8 bg-sky-50" />
          <div className="p-8 bg-blue-50" />
          <div className="p-8 bg-indigo-50" />
          <div className="p-8 bg-violet-50" />
          <div className="p-8 bg-purple-50" />
          <div className="p-8 bg-fuchsia-50" />
          <div className="p-8 bg-pink-50" />
          <div className="p-8 bg-rose-50" />
        </div>
        <div className="grid grid-cols-8 gap-4">

          <div className="p-8 bg-gray-100" />
          <div className="p-8 bg-stone-100" />
          <div className="p-8 bg-red-100" />
          <div className="p-8 bg-red-100" />
          <div className="p-8 bg-orange-100" />
          <div className="p-8 bg-amber-100" />
          <div className="p-8 bg-yellow-100" />
          <div className="p-8 bg-lime-100" />
          <div className="p-8 bg-green-100" />
          <div className="p-8 bg-emerald-100" />
          <div className="p-8 bg-teal-100" />
          <div className="p-8 bg-cyan-100" />
          <div className="p-8 bg-sky-100" />
          <div className="p-8 bg-blue-100" />
          <div className="p-8 bg-indigo-100" />
          <div className="p-8 bg-violet-100" />
          <div className="p-8 bg-purple-100" />
          <div className="p-8 bg-fuchsia-100" />
          <div className="p-8 bg-pink-100" />
          <div className="p-8 bg-rose-100" />
        </div>

        <div className="grid grid-cols-8 gap-4">

          <div className="p-8 dark:bg-gray-900" />
          <div className="p-8 dark:bg-stone-900" />
          <div className="p-8 dark:bg-red-900" />
          <div className="p-8 dark:bg-red-900" />
          <div className="p-8 dark:bg-orange-900" />
          <div className="p-8 dark:bg-amber-900" />
          <div className="p-8 dark:bg-yellow-900" />
          <div className="p-8 dark:bg-lime-900" />
          <div className="p-8 dark:bg-green-900" />
          <div className="p-8 dark:bg-emerald-900" />
          <div className="p-8 dark:bg-teal-900" />
          <div className="p-8 dark:bg-cyan-900" />
          <div className="p-8 dark:bg-sky-900" />
          <div className="p-8 dark:bg-blue-900" />
          <div className="p-8 dark:bg-indigo-900" />
          <div className="p-8 dark:bg-violet-900" />
          <div className="p-8 dark:bg-purple-900" />
          <div className="p-8 dark:bg-fuchsia-900" />
          <div className="p-8 dark:bg-pink-900" />
          <div className="p-8 dark:bg-rose-900" />
        </div>
        <div className="grid-cols-4 bg-red-600 p-2" />
        <div className="text-brand-700 text-gray-500 bg-brand-600
        rounded-md shadow-sm"
        />
        <button
          className="
    flex
    px-4 py-2
    text-white
    rounded-md
    shadow-sm
    font-medium
    text-sm
    uppercase
  cursor-not-allowed
    bg-red-400
    hover:bg-red-400
  "
          type="button"
        >
          <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="h-5 w-5"><path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg></span>
          Hapus

        </button>
        <button
          className="
        px-4 py-2
        bg-indigo-600
        hover:bg-indigo-500
        text-white
        rounded-md
        shadow-sm
        font-medium
        text-sm
        uppercase
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-indigo-500
      "
          type="button"
        >
          Hello

        </button>
        <button
          className="
    relative
    rounded-md
    shadow-sm
    font-medium
    text-sm
    uppercase
    transition-all
   px-4 py-2
      text-white

      focus:outline-none
      focus:ring-2
      focus:ring-offset-2
      focus:ring-brand-500

      bg-brand-600
      dark:bg-brand-800
      hover:bg-brand-500
      hover:dark:bg-brand-700
     w-full text-center "
          type="submit"
        >
          <span className="absolute left-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="h-5 w-5"><path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" /></svg></span>
          <span className="
          pl-8
        "
          >
            Login
          </span>

        </button>
      </div>

      jonson
    </div>
  )
}

import db from './db'

const keuController = {
  index: (params = {}) => {
    let dexie = db.keu
    if ('orderBy' in params) {
      dexie = dexie.orderBy(params.orderBy)
    }
    if ('reverse' in params && params.reverse) {
      dexie = dexie.reverse()
    }
    if ('filter' in params) {
      dexie = dexie.filter((e) => params.filter(e))
    }
    if ('where' in params && 'equals' in params) {
      dexie = dexie.where(params.where).equals(params.equals)
    }
    if ('first' in params && params.first) {
      dexie = dexie.first()
    } else {
      dexie = dexie.toArray()
    }
    return dexie
  },
  store: (data) => db.keu.add(data),
  storeMulti: (data) => db.keu.bulkAdd(data),
  update: (id, data) => db.keu.update(id, data),
  updateMulti: (data) => db.keu.bulkPut(data),
  delete: (params) => {
    let dexie = db.keu
    if ('where' in params && 'above' in params) {
      dexie = dexie.where(params.where).above(params.above)
    }
    return dexie.delete()
  }
}

export default keuController

import db from './db'

const keuBudgetController = {
  index: () => db.keuBudget.toArray(),
  storeMulti: (data) => db.keuBudget.bulkAdd(data),
  delete: (params) => {
    let dexie = db.keuBudget
    if ('where' in params && 'above' in params) {
      dexie = dexie.where(params.where).above(params.above)
    }
    return dexie.delete()
  }
}

export default keuBudgetController

import Dexie from 'dexie'
// https://dexie.org/docs/API-Reference#add-items

const db = new Dexie('keuangan')

db.version(1).stores({
  userLogin: '++id, user, keuUser, role, currentRole',
  keu: '++id, id_keu, id_keu_user, id_keu_dompet, id_keu_kat, date, debet, kredit, comment, id_sync, created_at, updated_at, deleted_at',
  keuDompet: '++id, id_keu_dompet, id_keu_user, name, desc, icon, color, id_sync, created_at, updated_at, deleted_at',
  keuKat: '++id, id_keu_user, name, icon, color, balance_type, is_transfer, id_keu_dompet_from, id_keu_dompet_to, id_sync, created_at, updated_at, deleted_at',
  keuBudget: '++id, id_keu_user, id_keu_kat, month, year, nominal, comment, id_sync, created_at, updated_at, deleted_at'
})

export default db

import keuBudgetController from './keuBudgetController'
import keuController from './keuController'
import keuDompetController from './keuDompetController'
import keuKatController from './keuKatController'
import userLoginController from './userLoginController'

const dexieApi = {
  keu: keuController,
  dompet: keuDompetController,
  kategori: keuKatController,
  budget: keuBudgetController,
  userLogin: userLoginController
}

export default dexieApi

export default function setTheme() {
  if (localStorage.theme) {
    const root = window.document.documentElement
    root.classList.remove('dark', 'light')
    root.classList.add(localStorage.theme)
    localStorage.setItem('theme', localStorage.theme)
  } else {
    localStorage.setItem('theme', 'light')
  }
}

/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'

export const transaksi = createSlice({
  name: 'transaksi',
  initialState: {
    allTransaksi: [],
    transaksiList: [],
    modeView: 1,
    filter: {
      idKeuDompet: null,
      idKeuKat: null,
      month: null,
      year: null
    },
    listBulan: [],
    listTahun: []
  },
  reducers: {
    setAllTransaksi: (state, val) => {
      state.allTransaksi = val.payload
    },
    setTransaksiList: (state, val) => {
      state.transaksiList = val.payload
    },
    setModeView: (state, val) => {
      state.modeView = val.payload
    },
    setFilter: (state, val) => {
      state.filter = val.payload
    },
    setListBulanDanTahun: (state, val) => {
      state.listBulan = val.payload.listBulan
      state.listTahun = val.payload.listTahun
    }
    // setListBulan: (state, val) => {
    //   state.listBulan = val.payload
    // },
    // setListTahun: (state, val) => {
    //   state.listTahun = val.payload
    // },
  }
})

export const {
  setAllTransaksi, setTransaksiList, setModeView, setFilter, setListBulanDanTahun
} = transaksi.actions

export default transaksi.reducer

import db from './db'

const keuDompetController = {
  index: () => db.keuDompet.toArray(),
  storeMulti: (data) => db.keuDompet.bulkAdd(data),
  delete: (params) => {
    let dexie = db.keuDompet
    if ('where' in params && 'above' in params) {
      dexie = dexie.where(params.where).above(params.above)
    }
    return dexie.delete()
  }
}

export default keuDompetController

import React, { lazy, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'

/**
 * middleware
 */
import AuthMiddleware from '../middleware/AuthMiddleware'
import OnlyPublicMiddleware from '../middleware/OnlyPublicMiddleware'

/**
 * component tanpa lazy load
 */
import LoadingPage from '../components/global/LoadingPage'
import Develop from '../pages/develop/index'

/**
 * pages
 */
const Index = lazy(() => import('../pages/index'))

const Berita = lazy(() => import('../pages/berita/index'))
const Login = lazy(() => import('../pages/login/index'))
const TryIndex = lazy(() => import('../pages/try/index'))
const Register = lazy(() => import('../pages/register/index'))

const User = lazy(() => import('../pages/user/index'))
const UserAkun = lazy(() => import('../pages/user/akun/index'))
const UserBerita = lazy(() => import('../pages/user/berita/index'))
const UserDompet = lazy(() => import('../pages/user/dompet/index'))
const UserKategori = lazy(() => import('../pages/user/kategori/index'))
const UserLog = lazy(() => import('../pages/user/log/index'))
const UserProfil = lazy(() => import('../pages/user/profil/index'))
const UserProfilUsername = lazy(() => import('../pages/user/profil/_username/index'))
const UserRencana = lazy(() => import('../pages/user/rencana/index'))
const UserSinkronisasi = lazy(() => import('../pages/user/sinkronisasi/index'))
const UserUploadData = lazy(() => import('../pages/user/upload-data/index'))
const UserTransaksi = lazy(() => import('../pages/user/transaksi/index'))
const UserSearchIndex = lazy(() => import('../pages/user/search/index'))

const DocIndexeddb = lazy(() => import('../pages/doc/indexeddb/index'))
const DocIndex = lazy(() => import('../pages/doc/index'))
const DocCompIndex = lazy(() => import('../pages/doc/comp/index'))
const DocNewPage = lazy(() => import('../pages/doc/new-page/index'))

const DevelopDecrypt = lazy(() => import('../pages/develop/decrypt/index'))

const Routes = () => useRoutes([
  {
    path: '/',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <Index />
      </Suspense>
    )
  },
  {
    path: '/try',
    element: (
      <OnlyPublicMiddleware>
        <Suspense fallback={<LoadingPage />}>
          <TryIndex />
        </Suspense>
      </OnlyPublicMiddleware>
    )
  },
  {
    path: '/berita',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <Berita />
      </Suspense>
    )
  },
  {
    path: '/login',
    element: (
      <OnlyPublicMiddleware>
        <Suspense fallback={<LoadingPage />}>
          <Login />
        </Suspense>
      </OnlyPublicMiddleware>
    )
  },
  {
    path: '/register',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <Register />
      </Suspense>
    )
  },
  {
    path: '/user',
    element: (
      <AuthMiddleware abilities="['admin', 'user']">
        <Suspense fallback={<LoadingPage />}>
          <User />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/akun',
    element: (
      <AuthMiddleware abilities="['admin']">
        <Suspense fallback={<LoadingPage />}>
          <UserAkun />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/berita',
    element: (
      <AuthMiddleware abilities="['admin']">
        <Suspense fallback={<LoadingPage />}>
          <UserBerita />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/dompet',
    element: (
      <AuthMiddleware abilities="['user']">
        <Suspense fallback={<LoadingPage />}>
          <UserDompet />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/kategori',
    element: (
      <AuthMiddleware abilities="['user']">
        <Suspense fallback={<LoadingPage />}>
          <UserKategori />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/log',
    element: (
      <AuthMiddleware abilities="['admin']">
        <Suspense fallback={<LoadingPage />}>
          <UserLog />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/profil',
    element: (
      <AuthMiddleware abilities="['admin', 'user']">
        <Suspense fallback={<LoadingPage />}>
          <UserProfil />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/profil/:username',
    element: (
      <AuthMiddleware abilities="['admin']">
        <Suspense fallback={<LoadingPage />}>
          <UserProfilUsername />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/rencana',
    element: (
      <AuthMiddleware abilities="['user']">
        <Suspense fallback={<LoadingPage />}>
          <UserRencana />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/sinkronisasi',
    element: (
      <AuthMiddleware abilities="['user']">
        <Suspense fallback={<LoadingPage />}>
          <UserSinkronisasi />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/upload-data',
    element: (
      <AuthMiddleware abilities="['user']">
        <Suspense fallback={<LoadingPage />}>
          <UserUploadData />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/transaksi',
    element: (
      <AuthMiddleware abilities="['user']">
        <Suspense fallback={<LoadingPage />}>
          <UserTransaksi />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/user/search',
    element: (
      <AuthMiddleware abilities="['user']">
        <Suspense fallback={<LoadingPage />}>
          <UserSearchIndex />
        </Suspense>
      </AuthMiddleware>
    )
  },
  {
    path: '/doc',
    element: (
      <DocIndex />
    )
  },
  {
    path: '/doc/comp',
    element: (
      <DocCompIndex />
    )
  },
  {
    path: '/doc/new-page',
    element: (
      <DocNewPage />
    )
  },
  {
    path: '/doc/indexeddb',
    element: (
      <DocIndexeddb />
    )
  },
  {
    path: '/develop',
    element: (
      <Develop />
    )
  },
  {
    path: '/develop/decrypt',
    element: (
      <DevelopDecrypt />
    )
  }
])

export default Routes

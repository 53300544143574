import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import axios from 'axios'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import store from './store'

axios.defaults.withCredentials = true
// axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL
axios.defaults.baseURL = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_API_URL_DEV : process.env.REACT_APP_BASE_API_URL)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
)
// NOTE: Hapus React.StricMode supaya gak ngecall 2 x pas useEffect
// (tapi ternyata cuma pas dev aja call 2x, pas di build, sekali aja kok)
// https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

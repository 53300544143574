import { Transition } from '@headlessui/react'
import React from 'react'

export default function LoadingPage() {
  return (
    <div className="fixed z-50 top-0 min-h-screen w-full bg-white dark:bg-gray-900 dark:text-gray-200">
      <Transition
        appear
        show
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-screen-lg mx-auto">
          <p className="pt-48 text-center animate-pulse">
            <img
              className="mx-auto h-20 w-auto"
              src="/apple-touch-icon-180x180.png"
              alt="keuangan"
            />
            <br />
            Loading...
          </p>
        </div>
      </Transition>
    </div>
  )
}

import authApi from './authApi'
import budgetApi from './budgetApi'
import dompetApi from './dompetApi'
import kategoriApi from './kategoriApi'
import keuanganApi from './keuanganApi'

const api = {
  auth: authApi,
  keuangan: keuanganApi,
  dompet: dompetApi,
  kategori: kategoriApi,
  budget: budgetApi
}

export default api

import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth'
import counterReducer from './counter/counterSlice' // ini hanya example
import dompetReducer from './dompet'
import mainReducer from './main'
import transaksiReducer from './transaksi'
import kategoriReducer from './kategori'
import toastReducer from './toast'

export default configureStore({
  reducer: {
    auth: authReducer,
    dompet: dompetReducer,
    counter: counterReducer,
    main: mainReducer,
    transaksi: transaksiReducer,
    kategori: kategoriReducer,
    toast: toastReducer
  }
})

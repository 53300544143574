/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'

export const kategori = createSlice({
  name: 'kategori',
  initialState: {
    kategoriList: []
  },
  reducers: {
    setKategoriList: (state, val) => {
      state.kategoriList = val.payload
    }
  }
})

export const {
  setKategoriList
} = kategori.actions

export default kategori.reducer
